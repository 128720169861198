export const SPECIALTIES = [
  "Acute geneeskunde",
  "Abdominale heelkunde",
  "Algemene heelkunde",
  "Anesthesie",
  "Arbeidsgeneeskunde",
  "Beheer van gezondheidsgegevens",
  "Cardiologie",
  "Dermato-venereologie",
  "Endocrino-diabetologie",
  "Forensische psychiatrie",
  "Functionele en professionele revalidatie van personen met een handicap",
  "Fysische geneeskunde en revalidatie",
  "Gastro-enterologie",
  "Gerechtelijke geneeskunde",
  "Geriatrie",
  "Gynaecologie-verloskunde",
  "Hematologie",
  "Intensieve zorgen",
  "Inwendige geneeskunde",
  "Kinder- en jeugdpsychiatrie",
  "Klinische biologie",
  "Klinische genetica",
  "Klinische hematologie",
  "Klinische infectiologie",
  "Medische microbiologie",
  "Medische oncologie",
  "Mond-, kaak- en aangezichtschirurgie",
  "Nefrologie",
  "Neonatologie",
  "Neurochirurgie",
  "Neurologie",
  "Niet-heelkundige esthetische geneeskunde",
  "Nucleaire geneeskunde",
  "Oftalmologie",
  "Oncologische gastro-enterologie",
  "Oncologische pneumologie",
  "Orthopedische heelkunde",
  "Otorhinolaryngologie",
  "Pathologische anatomie",
  "Pediatrie",
  "Pediatrische hematologie en oncologie",
  "Pediatrische neurologie",
  "Plastische, reconstructieve en esthetische",
  "Heelkunde",
  "Pneumologie",
  "Psychiatrie",
  "Radiotherapie-oncologie",
  "Reumatologie",
  "Radiologie",
  "Stomatologie",
  "Urgentiegeneeskunde",
  "Urologie",
  "Vaatheelkunde",
  "Verzekeringsgeneeskunde en medische expertise",
  "Volwassenpsychiatrie",
  "HippoDX - ABL study",
  "HippoDX - AZH study",
  "FRAME",
  "InterSystems Respiratory Oncology",
  "Ergotherapie",
  "Andere",
  "Synops Dev",
  "Synops Applicatiebeheer",
];
