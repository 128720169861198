/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { models__api__identifier_token__Identifier_Output } from './models__api__identifier_token__Identifier_Output';

export type SupervisorIdentifier_Output = {
    identifier: models__api__identifier_token__Identifier_Output;
    type?: SupervisorIdentifier_Output.type;
    display?: (string | null);
};

export namespace SupervisorIdentifier_Output {

    export enum type {
        PRACTITIONER = 'Practitioner',
    }


}

