import { useQuery, UseQueryOptions } from "react-query";
import keyFactory from "./keyFactory";
import client, { ApiError, UserOut } from "./client";

const STALE_TIME = 60 * 1000 * 10; // 10 minutes
export const currentUserDataQuery = {
  queryKey: keyFactory.userData("me"),
  queryFn: () => client.v1.getUserMeV1(),
  staleTime: STALE_TIME,
};
export type CurrentUserDataQuery = Pick<
  typeof currentUserDataQuery,
  "queryFn" | "queryKey"
> &
  UseQueryOptions;

export const currentUserDataOrFalseQuery = {
  queryKey: keyFactory.userData("me"),
  queryFn: () =>
    client.v1.getUserMeV1().catch((error) => {
      if (error.status === 404 || error.status === 401) {
        return false as const;
      }
      throw error;
    }),
  staleTime: STALE_TIME,
};
export type CurrentUserDataOrFalseQuery = Pick<
  typeof currentUserDataOrFalseQuery,
  "queryFn" | "queryKey"
>;

export function useCurrentUserData<TData = UserOut>(
  options: UseQueryOptions<UserOut | false, ApiError, TData> = {},
) {
  return useQuery<UserOut | false, ApiError, TData>({
    ...currentUserDataOrFalseQuery,
    ...options,
  });
}

export default useCurrentUserData;
