import { IfhirR4 } from "@smile-cdr/fhirts";
import { PatientLink } from "@smile-cdr/fhirts/dist/FHIR-R4/classes/patientLink";
import { CONFIG } from "@/config";
import Identifier from "@/data-models/value-models/identifier";
import { signInWithCustomToken } from "firebase/auth";
import { FHIREndpoint } from "@/hooks/useFHIREndpoint";
import {
  PATIENT_DETAILS_PAGE,
  PATIENT_DETAILS_PATH,
} from "@/pages/patients/const";
import { PATIENT_DISPATCH_PATH } from "@/pages/patients/dispatch/const";
import { LoaderFunction, redirect } from "react-router";
import { getTenantAuth } from "@/services/tenant";
import { z } from "zod";
import { SMART_ISS_TO_CLIENT_ID } from "../consts";

const loader: LoaderFunction = async ({
  request,
}): Promise<LoaderData | Response> => {
  const url = new URL(request.url);
  const params = new URLSearchParams(url.search);
  if (params.get("error")) {
    throw new Error(params.get("error_description") || "Unknown error");
  }
  const code = params.get("code");
  const state = params.get("state");
  const expectedState = sessionStorage.getItem("state");
  if (state !== expectedState) {
    throw new Error(
      `Invalid 'state' parameter. Expected: ${expectedState} Got: ${state}}`,
    );
  }
  if (!code) {
    throw new Error("Missing 'code' parameter");
  }
  const token_endpoint = sessionStorage.getItem("token_endpoint");
  if (!token_endpoint) {
    throw new Error("Missing 'token_endpoint' parameter in session storage.");
  }
  const iss = sessionStorage.getItem("iss");
  if (!iss || !SMART_ISS_TO_CLIENT_ID.has(iss)) {
    throw new Error("Missing 'iss' parameter in session storage.");
  }
  const code_verifier = sessionStorage.getItem("code_verifier");
  const client_id = SMART_ISS_TO_CLIENT_ID.get(iss)!;
  const tokenRequestData = {
    grant_type: "authorization_code",
    client_id,
    code,
    redirect_uri: `${url.origin}/smart/redirect`, //TODO replace this with CONST
    code_verifier,
  };

  const tokenParams = new URLSearchParams();
  Object.entries(tokenRequestData)
    .filter((entry): entry is [string, string] => entry[1] != null)
    .forEach(([key, value]) => {
      tokenParams.append(key, value);
    });

  console.debug("Fetching access token");
  const accessTokenResponse = await fetch(token_endpoint, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
      "ngrok-skip-browser-warning": "true",
    },
    credentials: "include",
    method: "POST",
    body: tokenParams.toString(),
  });
  const accessTokenData = await accessTokenResponse.json();
  if (accessTokenData.error) {
    throw new Error(
      accessTokenData.error_description ||
        accessTokenData.error_uri ||
        "Unknown error",
    );
  }
  const accessToken = accessTokenData.access_token;
  if (!accessToken) {
    throw new Error("Missing 'access_token' parameter");
  } else {
    const endpoint: FHIREndpoint = {
      baseURL: new URL(iss.endsWith("/") ? iss : `${iss}/`).toString(),
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/fhir+json",
        "Content-Type": "application/fhir+json",
      },
    };
    sessionStorage.setItem("fhirEndpoint", JSON.stringify(endpoint));
  }

  const id_token = accessTokenData.id_token;
  if (!id_token) {
    throw new Error("Missing 'id_token' parameter");
  }
  console.debug("Token data fetched. id_token:", id_token);

  console.log("Exchange id_token for firebase credential");
  console.debug(CONFIG.TOKEN_EXCHANGE, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
    },
    method: "POST",
    body: createTokenExchangeBody(id_token),
  });

  const customToken = await fetch(CONFIG.TOKEN_EXCHANGE.href, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
    },
    credentials: "include",
    method: "POST",
    body: createTokenExchangeBody(id_token),
  }).then(parseTokenExchangeResponse);

  console.log("Received custom token", customToken);

  const auth = getTenantAuth();
  console.log("Signing in with custom_token", customToken);
  const userCredential = await signInWithCustomToken(auth, customToken);
  console.log("User credential", userCredential);
  const patient = accessTokenData.patient;
  if (!patient) {
    throw new Error("Missing 'patient' parameter");
  }
  const encounter: string | undefined = accessTokenData.encounter;

  const patientResponse = await fetch(`${iss}/Patient/${patient}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "ngrok-skip-browser-warning": "true",
    },
    credentials: "include",
  });
  const patientResource = await patientResponse.json();
  const idToken = await userCredential.user.getIdToken();
  const idTokenResult = await userCredential.user.getIdTokenResult();
  const fhirUser = z.string().safeParse(idTokenResult.claims.fhirUser);
  if (fhirUser.success) {
    const fhirUserResponse = await fetch(`${iss}/${fhirUser.data}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
      credentials: "include",
    });
    const fhirUserResource = await fhirUserResponse.json();
    if (fhirUserResource.resourceType === "PractitionerRole") {
      const practitioner = fhirUserResource.practitioner.reference;
      const practitionerResponse = await fetch(`${iss}/${practitioner}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        credentials: "include",
      });
      const practitionerResource = await practitionerResponse.json();
      const practitionerEndpoint = new URL(
        `${practitionerResource.resourceType}/`,
        CONFIG.fhirURL,
      );
      await fetch(practitionerEndpoint.href, {
        method: "POST",
        headers: {
          "Content-Type": "application/fhir+json",
          Authorization: `Bearer ${idToken}`,
        },
        credentials: "include",
        body: JSON.stringify(practitionerResource),
      });
    } else if (fhirUserResource.resourceType === "Practitioner") {
      const fhirUserEndpoint = new URL(
        `${fhirUserResource.resourceType}/`,
        CONFIG.fhirURL,
      );
      await fetch(fhirUserEndpoint.href, {
        method: "POST",
        headers: {
          "Content-Type": "application/fhir+json",
          Authorization: `Bearer ${idToken}`,
        },
        credentials: "include",
        body: JSON.stringify(fhirUserResource),
      });
    } else {
      throw new Error("Invalid FHIR User");
    }
    const patientEndpoint = new URL("Patient/", CONFIG.fhirURL);
    const patientResponse = await fetch(patientEndpoint.href, {
      method: "POST",
      headers: {
        "Content-Type": "application/fhir+json",
        Authorization: `Bearer ${idToken}`,
      },
      credentials: "include",
      body: JSON.stringify(
        addPatientBackLink(patientResource, `${iss}/Patient/${patient}`),
      ),
    });
    const patientResponseResource = await patientResponse.json();
    return redirect(
      `/${PATIENT_DETAILS_PATH.replace(
        PATIENT_DETAILS_PAGE,
        patientResponseResource.id,
      )}`,
    );
  }

  const encounterResponse =
    encounter &&
    (await fetch(`${iss}/Encounter/${encounter}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "ngrok-skip-browser-warning": "true",
      },
      credentials: "include",
    }));
  const encounterResource =
    encounterResponse && (await encounterResponse.json());

  if (!CONFIG.isDev) {
    const params = new URLSearchParams();
    const patientIdentifier: Identifier | undefined =
      patientResource.identifier?.[0] &&
      new Identifier(patientResource.identifier[0]);
    if (patientIdentifier) params.set("patient", patientIdentifier.toToken());
    params.set(
      "encounter",
      encounterResource?.identifier[0] ?? encounterResource?.id,
    );
    if (patientResource.gender) params.set("sex", patientResource.gender);
    if (patientResource.birthDate) params.set("dob", patientResource.birthDate);
    return redirect(`/${PATIENT_DISPATCH_PATH}?${params.toString()}`);
  }

  return {
    patient: patientResource,
    encounter: encounterResource,
    id_token,
  };
};

export type LoaderData = {
  patient: IfhirR4.IPatient;
  encounter: IfhirR4.IEncounter;
  id_token: string;
};
export default loader;

function createTokenExchangeBody(id_token: string) {
  const formData = new URLSearchParams();
  formData.append(
    "grant_type",
    "urn:ietf:params:oauth:grant-type:token-exchange",
  );
  formData.append(
    "requested_token_type",
    "urn:ietf:params:oauth:token-type:id_token",
  );
  formData.append(
    "subject_token_type",
    "urn:ietf:params:oauth:token-type:id_token",
  );
  formData.append("subject_token", id_token);
  return formData.toString();
}

const TokenExchangeResponseSchema = z.object({
  access_token: z.string(),
  token_type: z.string(),
  issued_token_type: z.string(),
});

function parseTokenExchangeResponse(response: Response) {
  return response.json().then((data) => {
    if (data.error) {
      throw new Error(
        data.error_description || data.error_uri || "Unknown error",
      );
    }
    const parsed = TokenExchangeResponseSchema.parse(data);
    return parsed.access_token;
  });
}

function addPatientBackLink(patient: IfhirR4.IPatient, reference: string) {
  const referReference: PatientLink = {
    type: "refer",
    other: {
      reference,
      type: "Patient",
      display: `${patient.name?.[0].given?.join(" ")} ${
        patient.name?.[0].family
      } - ${patient.identifier?.[0].value}`,
    },
  };
  patient.link = patient.link || [];
  patient.link.push(referReference);
  return patient;
}
