import Tooltip from "../info-cards/Tooltip";
import { LockClosedIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import { UserTimestamp } from "@/data-models/block";

function ReadOnlyLock({ signed }: { signed: UserTimestamp }) {
  const timestamp = new Date(signed.timestamp);
  const who = signed.user.display;
  const when = timestamp.toLocaleDateString();
  const { t } = useTranslation();
  return (
    <Tooltip>
      <Tooltip.Trigger asChild>
        <LockClosedIcon
          className="mx-1 block h-5 w-5 text-gray-primary"
          aria-hidden="true"
        />
      </Tooltip.Trigger>
      <Tooltip.Content>
        <div className="rounded bg-black p-2 text-sm text-gray-50">
          {t("readonly-block", { who, when })}
        </div>
      </Tooltip.Content>
    </Tooltip>
  );
}

export default ReadOnlyLock;
