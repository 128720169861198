import Identifier from "@/data-models/value-models/identifier";
import Reference, {
  TiroPatientReference,
} from "@/data-models/value-models/reference";
import keyFactory from "@/services/reports/keyFactory";
import createReportFromTemplate from "@/services/createReportFromTemplate";
import client, {
  Encounter_Input as Encounter,
} from "@/services/reports/client";
import { QueryClient } from "react-query";
import { ActionFunction, redirect } from "react-router";
import { z } from "zod";
import { REPORT_EDIT_PATH } from "../edit/const";
import { createTemplateReportQuery } from "@/services/useCreateReportFromTemplate";
import { CONFIG } from "@/config";

type CreateActionConfig = {
  createReportFn: typeof client.v1.createReportV1;
  createTemplateReportQuery: typeof createTemplateReportQuery;
  queryClient: QueryClient;
};

export default ({
    createReportFn,
    createTemplateReportQuery,
    queryClient,
  }: CreateActionConfig): ActionFunction =>
  async ({ request, params }) => {
    // Parse the form data
    const formData = await request.formData();
    const { template } = parseFormData(formData);
    const url = new URL(request.url);
    const encounter = url.searchParams.get("encounter");
    const patient = z.coerce.number().parse(params.patientId);

    // Create the report
    const report = await createReportFromTemplate(template, {
      createReportFn,
      createTemplateReportQuery,
      queryClient,
      encounter: encounter
        ? new Reference({
            identifier: Identifier.fromToken(
              encounter,
              CONFIG.ENCOUNTER_ID_URI,
            ),
            type: "Encounter",
          })
        : undefined,
      subject: TiroPatientReference.fromPatientId(patient),
    });

    queryClient.invalidateQueries(keyFactory.allReports());
    // Update the cache with the new report and its blocks
    queryClient.setQueryData(keyFactory.reportBlocks(report.id), report.blocks);
    report.blocks?.forEach((block) => {
      queryClient.setQueryData(keyFactory.block(block.id), block);
    });

    // Redirect to the new report
    return redirect(
      `/${REPORT_EDIT_PATH.replace(":reportId", report.id.toString()).replace(":patientId", patient.toString())}`,
    );
  };

const FormSchema = z.object({
  template: z.string(),
  encounter: z.string().optional(),
});
function parseFormData(formData: FormData) {
  const formDataObj = Object.fromEntries(formData.entries());
  return FormSchema.parse(formDataObj);
}
