import classNames from "classnames";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SpecialismCombobox from "@/pages/auth/sign-up/SpecialismCombobox";
import { Form, useActionData, useNavigation } from "react-router-dom";
import { Spinner } from "@/Icons";
import useCurrentUserData from "@/services/reports/useUserData";
import { ActionData } from "./createAction";
import { toast } from "react-hot-toast";

const ProfileForm = () => {
  const { data: userData } = useCurrentUserData();
  const actionData = useActionData() as ActionData | undefined;
  const navigation = useNavigation();
  const { t } = useTranslation();
  useEffect(() => {
    if (actionData?.isSuccessful) {
      toast.success(t("profiel-bijgewerkt"));
    }
  }, [actionData?.isSuccessful, t]);
  return (
    <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
      <Form
        method="POST"
        className={classNames("divide-y divide-gray-200 lg:col-span-9")}
      >
        <div className="px-4 lg:pb-8">
          <div>
            <h2 className="text-lg font-medium capitalize leading-6 text-gray-900">
              {t("profiel")}
            </h2>
          </div>
          <div className="mt-6 grid grid-cols-12 gap-6">
            <div className="col-span-12 sm:col-span-6">
              <label
                htmlFor="firstName"
                className="block text-sm font-medium text-gray-dark"
              >
                {t("voornaam")}
              </label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                defaultValue={userData?.firstName ?? undefined}
                required
                autoComplete="given-name"
                className={classNames(
                  "mt-1 block w-full rounded-md border border-gray-light px-3 py-2 shadow-sm sm:text-sm",
                  {
                    "invalid:border-red-500 invalid:ring-1 invalid:ring-red-500":
                      !!actionData,
                    "focus:border-blue-500 focus:outline-none focus:ring-blue-500":
                      !actionData,
                  },
                )}
              />
            </div>
            <div className="col-span-12 sm:col-span-6">
              <label
                htmlFor="lastName"
                className="block text-sm font-medium text-gray-dark"
              >
                {t("familienaam")}
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                defaultValue={userData?.lastName ?? undefined}
                required
                autoComplete="family-name"
                className={classNames(
                  "mt-1 block w-full rounded-md border border-gray-light px-3 py-2 shadow-sm sm:text-sm",
                  {
                    "invalid:border-red-500 invalid:ring-1 invalid:ring-red-500":
                      !!actionData,
                    "focus:border-blue-500 focus:outline-none focus:ring-blue-500":
                      !actionData,
                  },
                )}
              />
            </div>

            {userData?.organisation && (
              <div className="col-span-12">
                <input
                  id="organisation"
                  name="organisation"
                  value={userData?.organisation}
                  className={classNames(
                    "block w-full appearance-none rounded-md border px-3 py-2 placeholder-gray-400 shadow-sm read-only:bg-gray-ultralight read-only:text-gray-800 focus:outline-none sm:text-sm",
                    "border-gray-light focus:border-blue-500 focus:ring-blue-500",
                  )}
                  autoComplete="organisation"
                  placeholder="organisatie"
                  type="text"
                  readOnly
                />
              </div>
            )}

            <div className="col-span-12">
              <SpecialismCombobox
                name="speciality"
                defaultValue={userData?.speciality}
              >
                <SpecialismCombobox.Label className="block text-sm font-medium text-gray-dark">
                  {t("specialty")}
                </SpecialismCombobox.Label>
                <SpecialismCombobox.Input
                  required
                  className={classNames({
                    "invalid:border-red-500 invalid:ring-1 invalid:ring-red-500":
                      !!actionData,
                    "focus:border-blue-500 focus:outline-none focus:ring-blue-500":
                      !actionData,
                  })}
                />
              </SpecialismCombobox>
            </div>
            <div className="col-span-12 sm:col-span-6">
              <label
                htmlFor="lastName"
                className="block text-sm font-medium capitalize text-gray-dark"
              >
                {t("language")}
              </label>
              <select
                id="language"
                name="language"
                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                defaultValue={userData?.language || "nl"}
              >
                <option value="nl">Nederlands</option>
                <option value="fr">Francais</option>
                <option value="en">English</option>
              </select>
            </div>
          </div>
        </div>
        <div className="divide-y divide-gray-200 pt-6">
          <div className="mt-4 flex justify-end px-4 py-4 sm:px-6">
            <button
              type="reset"
              className="inline-flex justify-center rounded-md border border-gray-light bg-white px-4 py-2 text-sm font-medium text-gray-dark shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              {t("annuleer")}
            </button>
            <button
              type="submit"
              className={classNames(
                "ml-5  inline-flex justify-center rounded-md  border border-transparent px-4 py-2 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2",
                "bg-blue-700 text-white shadow-sm hover:bg-blue-dark  focus:ring-blue-500",
                // "bg-gray-400 text-white shadow-inner": !isDirty,
              )}
            >
              {t("opslaan")}
              {navigation.state == "submitting" && (
                <Spinner className="ml-2 h-5 w-5 animate-spin text-white" />
              )}
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ProfileForm;
