/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { models__api__identifier_token__Identifier_Input } from './models__api__identifier_token__Identifier_Input';

export type SubjectIdentifier_Input = {
    identifier: models__api__identifier_token__Identifier_Input;
    type?: SubjectIdentifier_Input.type;
    display?: (string | null);
};

export namespace SubjectIdentifier_Input {

    export enum type {
        PATIENT = 'Patient',
    }


}

