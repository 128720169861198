import { getSessionSessionGet } from "./client";
import { authClient } from "./client/client";
import keyFactory from "./keyFactory";

export const getSessionQuery = {
  queryKey: keyFactory.currentSession(authClient),
  queryFn: () =>
    getSessionSessionGet({ client: authClient }).then(({ response }) =>
      response.json(),
    ),
};

export const hasSessionQuery = {
  queryKey: keyFactory.currentSession(authClient),
  queryFn: () =>
    getSessionSessionGet({ client: authClient }).then(
      ({ response }) => response.ok,
    ),
};

export type HasSessionQuery = Pick<
  typeof hasSessionQuery,
  "queryFn" | "queryKey"
>;
