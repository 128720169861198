import { AnyContentModel } from "@/components/blocks/content/models";
import { IContent, AnyRenderType } from "@/components/blocks/content/types";
import { RENDER_TYPES } from "@/components/blocks/content/consts";
import {} from "@/data-models/utils";
import { optional, string, type, Describe, enums, Struct } from "superstruct";
import { coerceNullToUndefined } from "./utils";
import { IReference } from "./value-models/types";

export interface UserReference extends IReference {
  id?: number;
  reference: `Practitioner/${string}`;
}

export type UserTimestamp = {
  user: UserReference;
  timestamp: string;
};

export interface IBlockMeta<R extends AnyRenderType = AnyRenderType> {
  id?: number;
  title: string;
  renderType: R;
  blockType: string;
  description?: string;
  created?: UserTimestamp;
  lastModified: UserTimestamp;
  signed?: UserTimestamp;
  readOnly?: string | null;
}

export const blockMeta = <
  R extends AnyRenderType = AnyRenderType,
  U extends readonly R[] = readonly R[],
>(
  renderType: U,
): Describe<IBlockMeta<U[number]>> => {
  const renderTypeModel = enums(renderType);
  const model = type({
    blockType: string(),
    renderType: renderTypeModel,
    title: string(),
    description: coerceNullToUndefined(optional(string())),
  });
  return model as any;
};

export interface IBlock<
  R extends AnyRenderType = AnyRenderType,
  C extends IContent<R> = IContent<R>,
> extends IBlockMeta<R> {
  content: C;
}

export const block = <
  R extends AnyRenderType,
  U extends readonly R[],
  C extends IContent<U[number]>,
>(
  renderType: U,
  content: Struct<C, any>,
): Describe<IBlock<U[number], C>> => {
  return type({
    content: content,
  }) as any;
};

// @ts-ignore
export const BlockModel = block(RENDER_TYPES, AnyContentModel);
