/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { models__api__identifier_token__Identifier_Output } from './models__api__identifier_token__Identifier_Output';

export type EncounterIdentifier_Output = {
    identifier: models__api__identifier_token__Identifier_Output;
    type?: EncounterIdentifier_Output.type;
    display?: (string | null);
};

export namespace EncounterIdentifier_Output {

    export enum type {
        ENCOUNTER = 'Encounter',
    }


}

