/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { models__api__identifier_token__Identifier_Output } from './models__api__identifier_token__Identifier_Output';

export type SubjectIdentifier_Output = {
    identifier: models__api__identifier_token__Identifier_Output;
    type?: SubjectIdentifier_Output.type;
    display?: (string | null);
};

export namespace SubjectIdentifier_Output {

    export enum type {
        PATIENT = 'Patient',
    }


}

