import { UnauthenticatedWhileEmbeddedError } from "@/errors";
import { isLaunchedFrom } from "@/hooks/useLaunchedFrom";
import { QueryClient } from "react-query";
import { LoaderFunction } from "react-router";
import { jumpBack } from "../auth-flow";
import { isAuthenticatedQuery } from "@/services/auth/useIsAuthenticated";
import { hasSessionQuery } from "@/services/auth/useSession";

export default (queryClient: QueryClient): LoaderFunction =>
  async ({ request }) => {
    const url = new URL(request.url);
    const isSynops = isLaunchedFrom(url, "Synops");
    const currentUser = await queryClient.fetchQuery(isAuthenticatedQuery);
    const hasSession = await queryClient.fetchQuery(hasSessionQuery);
    if (currentUser || hasSession) return jumpBack(url);
    if (isSynops && !currentUser) {
      throw new UnauthenticatedWhileEmbeddedError();
    }
    return null;
  };
