import { Combobox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { matchSorter } from "match-sorter";
import React, { useContext, useState } from "react";
import { SPECIALTIES } from "./SPECIALTIES";

export interface ISpecialismComboboxContext {
  query: string;
  setQuery: (query: string) => void;
  filteredSpecialisms: string[];
}

const SpecialismComboboxContext =
  React.createContext<ISpecialismComboboxContext | null>(null);

export interface SpecialismComboboxInput
  extends React.InputHTMLAttributes<HTMLInputElement> {}

function SpecialismComboboxInput({
  className,
  ...otherProps
}: SpecialismComboboxInput) {
  const context = useContext(SpecialismComboboxContext);
  if (!context) {
    throw Error(
      "<SpecialismCombobox.Input/> can only be used inside a <SpecialismCombobox/> context.",
    );
  }
  const { query, setQuery, filteredSpecialisms } = context;
  return (
    <div className="relative">
      <Combobox.Input
        as="input"
        className={classNames(
          "w-full rounded-md border border-gray-light bg-white py-2 pl-3 pr-10 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm",
          className,
        )}
        onChange={(event) => setQuery(event.target.value)}
        displayValue={(value: string) => value}
        {...otherProps}
      />
      <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
        <ChevronUpDownIcon
          className="h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
      </Combobox.Button>

      {filteredSpecialisms.length > 0 && (
        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {filteredSpecialisms.map((specialism) => (
            <Combobox.Option
              key={specialism}
              value={specialism}
              className={({ active }) =>
                classNames(
                  "relative cursor-default select-none py-2 pl-8 pr-4",
                  active ? "bg-blue-primary text-white" : "text-gray-900",
                )
              }
            >
              {({ active, selected }) => (
                <>
                  <span
                    className={classNames(
                      "block truncate",
                      selected && "font-semibold",
                    )}
                  >
                    {specialism}
                  </span>

                  {selected && (
                    <span
                      className={classNames(
                        "absolute inset-y-0 left-0 flex items-center pl-1.5",
                        active ? "text-white" : "text-blue-primary",
                      )}
                    >
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  )}
                </>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      )}
      {filteredSpecialisms.length == 0 && (
        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          <Combobox.Option
            key={query}
            value={query}
            className={({ active }) =>
              classNames(
                "relative cursor-default select-none py-2 pl-8 pr-4",
                active ? "bg-blue-primary text-white" : "text-gray-900",
              )
            }
          >
            {({ active, selected }) => (
              <>
                <span
                  className={classNames(
                    "block truncate",
                    selected && "font-semibold",
                  )}
                >
                  {query}
                </span>

                {selected && (
                  <span
                    className={classNames(
                      "absolute inset-y-0 left-0 flex items-center pl-1.5",
                      active ? "text-white" : "text-blue-primary",
                    )}
                  >
                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                  </span>
                )}
              </>
            )}
          </Combobox.Option>
        </Combobox.Options>
      )}
    </div>
  );
}

function SpecialismComboboxLabel({
  className,
  children,
  ...otherProps
}: Omit<React.HTMLAttributes<HTMLLabelElement>, "as">) {
  return (
    <Combobox.Label
      as="label"
      className={classNames(
        "block text-sm font-medium text-gray-dark",
        className,
      )}
      {...otherProps}
    >
      {children}
    </Combobox.Label>
  );
}

export interface SpecialismComboboxProps
  extends Omit<
    React.HTMLAttributes<HTMLDivElement>,
    "defaultValue" | "by" | "value" | "onChange"
  > {
  name?: string;
  selected?: string | null;
  onSelectedChange?: (specialism: string | null) => void;
  defaultValue?: string | null;
}

function SpecialismCombobox({
  className,
  name,
  selected,
  onSelectedChange,
  defaultValue,
  children,
  ...otherProps
}: SpecialismComboboxProps) {
  const [query, setQuery] = useState("");

  const filteredSpecialisms = matchSorter(SPECIALTIES, query);
  return (
    <Combobox
      as="div"
      name={name}
      defaultValue={defaultValue}
      value={selected}
      onChange={onSelectedChange}
      className={className}
      {...otherProps}
    >
      <SpecialismComboboxContext.Provider
        value={{ query, setQuery, filteredSpecialisms }}
      >
        {children}
      </SpecialismComboboxContext.Provider>
    </Combobox>
  );
}

SpecialismCombobox.Input = SpecialismComboboxInput;
SpecialismCombobox.Label = SpecialismComboboxLabel;

export default SpecialismCombobox;
