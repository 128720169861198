import { CONFIG } from "@/config";
import Identifier from "@/data-models/value-models/identifier";
import { is, nonempty, string } from "superstruct";

export function parseTemplateFromURLParams(params: URLSearchParams) {
  const template = params.get("template");
  params.delete("template");
  if (!is(template, nonempty(string()))) return null;
  console.debug("Template from URL params", template);
  return template;
}

export function parseEncounterFromURLParams(params: URLSearchParams) {
  const encounter = params.get("encounter");
  params.delete("encounter");
  if (!is(encounter, nonempty(string()))) return null;
  const identifier = Identifier.fromToken(encounter, CONFIG.ENCOUNTER_ID_URI);
  return identifier;
}

export default function parseURLParams(request: Request) {
  const url = new URL(request.url);
  const unparsed = url.searchParams;
  const template = parseTemplateFromURLParams(unparsed);
  const encounter = parseEncounterFromURLParams(unparsed);

  return {
    encounter,
    template,
    unparsed,
  };
}
