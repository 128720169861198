/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { models__api__identifier_token__Identifier_Input } from './models__api__identifier_token__Identifier_Input';

export type SupervisorIdentifier_Input = {
    identifier: models__api__identifier_token__Identifier_Input;
    type?: SupervisorIdentifier_Input.type;
    display?: (string | null);
};

export namespace SupervisorIdentifier_Input {

    export enum type {
        PRACTITIONER = 'Practitioner',
    }


}

