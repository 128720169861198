import classNames from "classnames";
import SpecialismCombobox from "../sign-up/SpecialismCombobox";
import { Form, useActionData } from "react-router-dom";
import { ASK_USER_DATA_PATH } from "./const";
import { currentUserDataOrFalseQuery } from "@/services/reports/useUserData";
import { useLocation } from "react-router";
import { ActionData } from "./createAction";
import Panel from "../Panel";
import { useQuery } from "react-query";

function AskUserDataPanel() {
  const result = useActionData() as ActionData | undefined;
  const formattedError = result?.error;
  const { search } = useLocation();
  const { data: userData } = useQuery({
    ...currentUserDataOrFalseQuery,
    select: (data) => (data ? data : undefined),
  });
  return (
    <Panel className="space-y-6 sm:space-y-5 ">
      <div>
        <h3 className="text-xl font-medium leading-6 text-gray-900">Welkom!</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-primary">
          Voor je aan de slag kan, zouden we graag meer weten over jou. Dit
          helpt ons je een betere ervaring te geven.
        </p>
      </div>
      <Form
        className="mt-3 space-y-6"
        action={`/${ASK_USER_DATA_PATH}${search}`}
        method="POST"
      >
        <div>
          <legend className="block text-sm font-medium text-gray-dark">
            Naam
          </legend>

          <fieldset name="name" className="mt-1 grid grid-cols-2 gap-2">
            <label htmlFor="first-name" className="sr-only">
              Voornaam
            </label>
            <input
              id="first-name"
              type="text"
              autoComplete="given-name"
              name="firstName"
              defaultValue={userData?.firstName ?? ""}
              required
              className={classNames(
                "block w-full appearance-none rounded-md border px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm",
                {
                  "border-gray-light focus:border-blue-500 focus:outline-none focus:ring-blue-500":
                    !formattedError?.firstName,
                  "border-red-500 ring-1 ring-red-500":
                    !!formattedError?.firstName,
                },
              )}
              placeholder="voornaam"
            />
            {formattedError?.firstName && (
              <p className="text-xs italic text-red-500" data-testid="error">
                {formattedError.firstName}
              </p>
            )}
            <label htmlFor="last-name" className="sr-only">
              Familienaam
            </label>
            <input
              id="last-name"
              type="text"
              autoComplete="family-name"
              name="lastName"
              defaultValue={userData?.lastName ?? ""}
              required
              className={classNames(
                "block w-full appearance-none rounded-md border px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm",
                {
                  "border-gray-light focus:border-blue-500 focus:outline-none focus:ring-blue-500":
                    !formattedError?.lastName,
                  "border-red-500 ring-1 ring-red-500":
                    !!formattedError?.lastName,
                },
              )}
              placeholder="familienaam"
            />
            {formattedError?.lastName && (
              <p className="text-xs italic text-red-500" data-testid="error">
                {formattedError.lastName}
              </p>
            )}
          </fieldset>
        </div>
        {userData?.organisation && (
          <div>
            <label
              htmlFor="organisation"
              className="block text-sm font-medium text-gray-dark"
            >
              Organisatie
            </label>
            <div className="mt-1">
              <input
                id="organisation"
                name="organisation"
                value={userData?.organisation}
                className={classNames(
                  "block w-full appearance-none rounded-md border px-3 py-2 placeholder-gray-400 shadow-sm read-only:bg-gray-ultralight read-only:text-gray-800 focus:outline-none sm:text-sm",
                  "border-gray-light focus:border-blue-500 focus:ring-blue-500",
                )}
                autoComplete="organisation"
                placeholder="organisatie"
                type="text"
                readOnly
              />
            </div>
          </div>
        )}
        <SpecialismCombobox
          name="speciality"
          defaultValue={userData?.speciality}
        >
          <SpecialismCombobox.Label className="block text-sm font-medium text-gray-dark">
            Specialisatie
          </SpecialismCombobox.Label>
          <SpecialismCombobox.Input
            required
            data-testid="specialism"
            className={classNames({
              "border-gray-light focus:border-blue-500 focus:ring-blue-500":
                !formattedError?.speciality,
              "border-red-500 ring-1 ring-red-500":
                !!formattedError?.speciality,
            })}
          />
        </SpecialismCombobox>
        <div>
          <button
            name="submit"
            role="button"
            data-testid="submit"
            type="submit"
            className="flex w-full justify-center rounded-md border border-transparent bg-blue-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-400 hover:disabled:bg-gray-400 focus:disabled:ring-gray-primary"
          >
            Opslaan
          </button>
        </div>
      </Form>
    </Panel>
  );
}

export default AskUserDataPanel;
