import TD, { TopDownFormProps } from "@/components/forms/TopDownForm";
import { CodingModel } from "@/data-models/value-models/structs";
import { useTranslation } from "react-i18next";
import { assert } from "superstruct";
import { IQuestionItem } from "../..";
import { IEnabledWhenCondition } from "../../models";
import { ConceptFieldSet } from "./ConceptFieldSet";
import { ConditionalityFieldSet } from "./ConditionalityFieldSet";
import { FHIRExtractionFieldSet } from "./FHIRExtractionFieldSet";
import { OMOPExtractionFieldSet } from "./OMOPExtractionFieldSet";
import { FieldValues } from "./types";
import { SubmitHandler } from "react-hook-form";

interface QuestionPropertiesFormProps
  extends Omit<TopDownFormProps<FieldValues>, "onSubmit" | "children"> {
  actionLabel: string;
  questionValueSet?: string;
  onSubmit: (q: IQuestionItem) => void;
}

const DEFAULT_VALUES = { mode: "data-entry" } as const;

export const EMPTY_CONDITION: IEnabledWhenCondition = {
  operator: "=",
  operand: {
    display: "",
    system: "",
    code: "",
  },
};
export const EMPTY_EXTRACTION = { path: "", context: "", reverse: false };

function QuestionPropertiesForm({
  defaultValues,
  actionLabel,
  onSubmit,
  questionValueSet = "http://tiro.health/fhir/ValueSet/questions",
  ...props
}: QuestionPropertiesFormProps) {
  const { t } = useTranslation();

  return (
    <TD<FieldValues>
      {...props}
      defaultValues={{ ...DEFAULT_VALUES, ...defaultValues }}
    >
      {({ register }) => (
        <div>
          <ConceptFieldSet
            name="coding"
            valueSet={questionValueSet}
            className="border-b border-gray-lighter pb-2"
          />
          <ConditionalityFieldSet className="mt-2 border-b border-gray-lighter pb-2" />

          <FHIRExtractionFieldSet className="mt-2 border-b border-gray-lighter pb-2" />

          <OMOPExtractionFieldSet className="mt-2 border-b border-gray-lighter pb-2" />

          <fieldset className="mt-2 block pb-2">
            <TD.Option {...register("required")} label={t("verplicht")} small />
            <TD.Option {...register("hidden")} label={t("hidden")} small />
          </fieldset>
          <fieldset className="mt-2 block pb-2">
            <TD.Field
              {...register("type")}
              InputComponent={TD.Select as any}
              defaultValue="question"
              label={t("questionnaire-v2-question-type")!}
              small
            >
              <option value="subtitle" className="lowercase">
                {t("subtitle")}
              </option>
              <option value="question" className="lowercase">
                {t("question")}
              </option>
            </TD.Field>
          </fieldset>
          <fieldset className="mt-2 block pb-2">
            <TD.Field
              {...register("mode")}
              InputComponent={TD.Select as any}
              defaultValue="content-editable"
              label={t("questionnaire-v2-render-mode")!}
              small
            >
              <option value="data-entry">formulier</option>
              <option value="content-editable">teksteditor</option>
            </TD.Field>
          </fieldset>
          <TD.Submit small>{actionLabel}</TD.Submit>
        </div>
      )}
    </TD>
  );
}
export default QuestionPropertiesForm;
