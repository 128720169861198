import { CONFIG } from "@/config";
import { FHIREndpoint } from "@/hooks/useFHIREndpoint";
import { getTenantAuth } from "@/services/tenant";
import { z } from "zod";
import { FHIRQueryExpressionCompiler, IFHIRQueryExpression } from "./compile";

export type FHIRQueryOptions<TSchema extends z.ZodType = any> = {
  enabled?: boolean;
  schema?: TSchema;
  endpoint?: FHIREndpoint;
};

const COMPILER = new FHIRQueryExpressionCompiler();

export function createFHIRExpressionQuery<TSchema extends z.ZodType = any>(
  expression: IFHIRQueryExpression | undefined,
  options: FHIRQueryOptions<TSchema> = {},
) {
  const relativeURL = expression ? COMPILER.compile(expression) : undefined;
  const fhirBaseURL = options.endpoint?.baseURL
    ? new URL(options.endpoint.baseURL)
    : new URL(CONFIG.fhirURL);

  // fhirBaseURL is only used if relativeURL is not a valid absolute URL
  // !Note: leading slash will strip the existing path of fhirBaseURL
  const url = relativeURL ? new URL(relativeURL, fhirBaseURL) : undefined;

  return {
    queryKey: [fhirBaseURL, expression && COMPILER.getKey(expression)],
    queryFn: async () => {
      const headers: Record<string, string> =
        options.endpoint?.headers ?? (await createDefaultHeaders());
      const response = await fetch(url?.href!, {
        headers,
        credentials: "include",
      }).then((response) => response.json());
      if (options.schema) {
        return options.schema.parse(response);
      }
      return response;
    },
    enabled: !!expression && options.enabled !== false,
  };
}

export function createFHIRQuery<TSchema extends z.ZodType = any>(
  relativeURL: string | URL,
  options: FHIRQueryOptions<TSchema> = {},
) {
  const fhirBaseURL = options.endpoint?.baseURL
    ? new URL(options.endpoint.baseURL)
    : new URL(CONFIG.fhirURL);

  // fhirBaseURL is only used if relativeURL is not a valid absolute URL
  // !Note: leading slash will strip the existing path of fhirBaseURL
  console.log({ relativeURL, fhirBaseURL });
  const url = relativeURL ? new URL(relativeURL, fhirBaseURL) : undefined;

  return {
    queryKey: [fhirBaseURL, relativeURL.toString()],
    queryFn: async () => {
      const headers: Record<string, string> =
        options.endpoint?.headers ?? (await createDefaultHeaders());
      headers["accept"] = headers["accept"] ?? ["application/fhir+json"];
      const response = await fetch(url?.href!, {
        headers,
        credentials: "include",
      }).then((response) => response.json());
      if (options.schema) {
        return options.schema.parse(response);
      }
      return response;
    },
    enabled: options.enabled,
  };
}

async function createDefaultHeaders() {
  const headers: Record<string, string> = {
    accept: "application/fhir+json",
  };
  const idToken = await getTenantAuth().currentUser?.getIdToken();

  if (idToken) headers["authorization"] = `Bearer ${idToken}`;
  return headers;
}
