import { FormEvent } from "react";

export default function LaunchAuth() {
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formdata = new FormData(e.currentTarget);
    const eadnr = formdata.get("eadnummer");
    const provider = formdata.get("provider");
    if (!eadnr) {
      console.error("EAD-nummer is required");
      return;
    }
    if (!provider) {
      console.error("Provider is required");
      return;
    }

    const nextURL = new URL("/patients/dispatch", window.location.origin);
    nextURL.searchParams.append("patient", eadnr.toString());
    const next = encodeURIComponent(nextURL.toString());
    window.location.href = `https://auth.tiro.health/entra/login?provider=${provider}&next=${next}`;
  };
  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-100">
      <div className="w-full max-w-md rounded bg-white p-8 shadow-md">
        <h1 className="mb-6 text-center text-2xl font-bold text-blue-600">
          Launch Auth
        </h1>
        <form onSubmit={handleSubmit}>
          <label className="mb-4 block">
            <div className="mb-2 text-gray-700">Provider</div>
            <select
              name="provider"
              className="w-full rounded border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-600"
              defaultValue="uzleuven"
            >
              <option value="uzleuven">UZ Leuven</option>
              <option value="tiro-entra">Tiro.health</option>
            </select>
          </label>
          <label className="mb-4 block">
            <div className="mb-2 text-gray-700">EAD-nummer</div>
            <input
              type="text"
              name="eadnummer"
              className="w-full rounded border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-600"
            />
          </label>
          <button
            type="submit"
            className="w-full rounded bg-blue-600 py-2 text-white hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-600"
          >
            Launch
          </button>
        </form>
      </div>
    </div>
  );
}
