import { z } from "zod";

export default z.object({
  organisation: z.string().trim().min(1).nullable().optional(),
  speciality: z.string().trim().min(1),
  firstName: z.string().trim().min(1),
  lastName: z.string().trim().min(1),
  language: z
    .string()
    .nullable()
    .optional()
    .transform((v) => v ?? undefined),
});
