/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EncounterIdentifier_Input } from './EncounterIdentifier_Input';
import type { SubjectIdentifier_Input } from './SubjectIdentifier_Input';
import type { SubjectReference } from './SubjectReference';
import type { SupervisorIdentifier_Input } from './SupervisorIdentifier_Input';

/**
 * Model for report creation with canonical block urls and optionally a title and additional coding.
 */
export type ReportWithTemplateReference = {
    encounter?: (EncounterIdentifier_Input | null);
    subject: (SubjectReference | SubjectIdentifier_Input);
    supervisor?: (SupervisorIdentifier_Input | null);
    title?: (string | null);
    language?: ReportWithTemplateReference.language;
    template: string;
};

export namespace ReportWithTemplateReference {

    export enum language {
        NL_BE = 'nl-BE',
        EN = 'en',
        NL = 'nl',
    }


}

