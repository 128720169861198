import Tooltip from "@/components/info-cards/Tooltip";
import { Menu } from "@headlessui/react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import BlockMenuButton from "./ButtonItem";

type BlockSignButtonProps = {
  onClick: () => void;
  className?: string;
  disabled?: boolean;
  isSigned?: boolean;
};
export function BlockSignButton({
  onClick,
  className,
  disabled,
  isSigned,
}: BlockSignButtonProps) {
  const { t } = useTranslation();
  return (
    <Menu.Item
      as={BlockMenuButton}
      disabled={disabled}
      className={({ active, disabled }) =>
        classNames(
          {
            "bg-gray-ultralight": active && disabled,
            "bg-gray-50 text-gray-600": disabled,
          },
          "flex w-full whitespace-nowrap px-3 py-1.5 text-left text-sm text-gray-dark",
          className,
        )
      }
      onClick={onClick}
    >
      <span className="material-symbols-outlined text-base">done_all</span>
      <span className="ml-2">{t("sign-block")}</span>
    </Menu.Item>
  );
}

export function BlockUnsignButton({
  onClick,
  className,
  disabled,
  isSigned,
}: BlockSignButtonProps) {
  const { t } = useTranslation();
  return (
    <Menu.Item
      as={BlockMenuButton}
      disabled={disabled}
      className={({ active, disabled }) =>
        classNames(
          {
            "bg-gray-ultralight": active && disabled,
            "bg-gray-50 text-gray-600": disabled,
          },
          "flex w-full whitespace-nowrap px-3 py-1.5 text-left text-sm text-gray-dark",
          className,
        )
      }
      onClick={onClick}
    >
      <span className="material-symbols-outlined text-base">remove_done</span>
      <span className="ml-2">{t("unsign-block")}</span>
    </Menu.Item>
  );
}
